export enum AssistResourceFileType {
  Image = 1,
  Matterport = 2,
  Video = 3,
}

export interface AssistResourceFileBM {
  id: string;
  file: string;
  matterportUrl?: string;
  type: AssistResourceFileType;
  imagePreferCover: boolean;
}
