import { inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Localization, LOCALIZATIONS } from '@cue/translate';

export type FirstDayOfWeek = 'monday' | 'sunday';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  #translocoService = inject(TranslocoService);
  #localizations = inject(LOCALIZATIONS, { optional: true }) as unknown as Localization[][];

  public languageItems: Array<{ text: string; flag: string }> = [
    { text: 'CS', flag: 'cs.svg' },
    { text: 'EN', flag: 'en.svg' },
    { text: 'ES', flag: 'es.svg' },
    { text: 'DE', flag: 'ge.svg' },
    { text: 'FR', flag: 'fr.svg' },
    { text: 'CA', flag: 'ca.svg' },
    { text: 'UA', flag: 'ua.svg' },
  ].filter((lang) => this.#translocoService.getAvailableLangs().find((l) => l === lang.text.toLowerCase()) !== undefined);

  getLangFromCode(code: string) {
    const availableLangs: any[] = this.#translocoService.getAvailableLangs();
    const found = availableLangs.find((x) => x == code);
    return (
      this.languageItems.find((x) => x.text == (found ?? this.#translocoService.getDefaultLang()).toUpperCase())! ?? this.currentLanguage
    );
  }

  get currentLanguage(): { text: string; flag: string } {
    const availableLangs: any[] = this.#translocoService.getAvailableLangs();
    const langFromLocalStorage = localStorage.getItem('assist-lang');
    if (langFromLocalStorage != undefined) {
      const found = availableLangs.find((x) => x == langFromLocalStorage);
      return this.languageItems.find((x) => x.text == (found ?? this.#translocoService.getDefaultLang()).toUpperCase())!;
    } else {
      const found = availableLangs.find((x) => x == navigator.language.slice(0, 2));
      return this.languageItems.find((x) => x.text == (found ?? this.#translocoService.getDefaultLang()).toUpperCase())!;
    }
  }

  set currentLanguage(value: { text: string; flag: string }) {
    const availableLangs: any[] = this.#translocoService.getAvailableLangs();
    const found = availableLangs.find((x) => x == value.text.toLowerCase());

    if (found) {
      const translation = this.#localizations[0].find((x) => x.language == found)!;
      translation.valueGetter().then((x) => {
        localStorage.setItem('assist-lang', found);
        this.#translocoService.setTranslation(x, translation.language, {
          merge: false,
        });
        this.#translocoService.setActiveLang(found);
      });
    }
  }

  static availableCodes = {
    fr: () => 'fr',
    // ar: () => 'ar',
    no: () => 'nb',
    nb: () => 'nb',
    en: () => 'en',
    cs: () => 'cs',
    ca: () => 'ca',
    ua: () => 'ua',
    // de: () => 'de',
    es: () => 'es',
    // pl: () => 'pl',
    // it: () => 'it',
    // pt: () => 'pt',
  };

  #firstDayOfWeek: FirstDayOfWeek = 'monday';
  getFirstDayOfWeek(): FirstDayOfWeek {
    return this.#firstDayOfWeek;
  }

  setFirstDayOfWeek(day: FirstDayOfWeek) {
    this.#firstDayOfWeek = day;
  }

  getLocaleId() {
    const userLang = navigator.language || (navigator as any).userLanguage;
    const code = userLang.split('-')[0];
    const foundCode = LocaleService.availableCodes[code];
    const returnCode = foundCode ? foundCode() : 'en';

    return returnCode;
  }
}
